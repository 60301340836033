import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import _ from "lodash";

class BlogRoll extends React.Component {
  render() {
    const {
      language,
      data,
      selectedTag,
      featuredPostsOnly,
      limit,
    } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    let filteredPosts = posts.filter(
      (edge) => edge.node.frontmatter.language === language
    );

    if (selectedTag) {
      filteredPosts = filteredPosts.filter((edge) =>
        _.includes(edge.node.frontmatter.tags, selectedTag)
      );
    }

    if (featuredPostsOnly) {
      filteredPosts = filteredPosts.filter(
        (edge) => edge.node.frontmatter.featuredpost
      );
    }

    if (limit) {
      filteredPosts = filteredPosts.slice(0, limit);
    }

    return (
      <div className="md:flex flex-wrap -m-2">
        {filteredPosts &&
          filteredPosts.map(({ node: post }) => (
            <div key={post.id} className="md:w-1/3 lg:w-1/4 p-2 mb-10">
              {post.frontmatter.url ? (
                <a
                  className="block"
                  href={post.frontmatter.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="cover-image aspect-ratio-square"
                    style={{
                      backgroundImage: `url(${post.frontmatter.featuredimage.childImageSharp.fluid.src})`,
                    }}
                  ></div>
                </a>
              ) : (
                <Link className="block" to={post.fields.slug}>
                  <div
                    className="cover-image aspect-ratio-square"
                    style={{
                      backgroundImage: `url(${post.frontmatter.featuredimage.childImageSharp.fluid.src})`,
                    }}
                  ></div>
                </Link>
              )}
              <div className="flex justify-between text-sm p-1">
                <div>{post.frontmatter.date}</div>
                <div>{post.frontmatter.tags && post.frontmatter.tags[0]}</div>
              </div>
              <h4
                dangerouslySetInnerHTML={{ __html: post.frontmatter.title }}
              ></h4>
              {post.frontmatter.url ? (
                <a
                  className="btn btn-tertiary-sm"
                  href={post.frontmatter.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              ) : (
                <Link className="btn btn-tertiary-sm" to={post.fields.slug}>
                  Learn more
                </Link>
              )}
            </div>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  language: PropTypes.string,
  selectedTag: PropTypes.string,
  featuredPostsOnly: PropTypes.bool,
  limit: PropTypes.number,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const BlogRollQuery = ({ language, selectedTag, featuredPostsOnly, limit }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                language
                title
                url
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 768, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll
        data={data}
        count={count}
        language={language}
        featuredPostsOnly={featuredPostsOnly}
        selectedTag={selectedTag}
        limit={limit}
      />
    )}
  />
);

BlogRollQuery.propTypes = {
  language: PropTypes.string,
  selectedTag: PropTypes.string,
  featuredPostsOnly: PropTypes.bool,
  limit: PropTypes.number,
};

export default BlogRollQuery;
